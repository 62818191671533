
  import { computed, defineComponent, PropType, watch } from 'vue';
  import axios from 'axios';
  import { cloneDeep, first, get } from 'lodash';
  import { mapState, useStore } from 'vuex';

  import { Swiper, SwiperSlide } from 'swiper/vue';
  import SwiperCore, { Navigation, Pagination, Thumbs, Zoom } from 'swiper';
  import 'swiper/swiper.scss';
  import 'swiper/components/navigation/navigation.scss';
  import 'swiper/components/pagination/pagination.scss';
  import { gsap } from 'gsap';

  import ProductAPI from '@/API/productApi';
  import useAuthenticateUser from '@/composables/useAuthenticateUser';

  import { extractYoutubeId } from '@/utils/stringHelper';
  import { ImageHandler } from '@/utils/imageHelper';
  import { getAreaForPage } from '@/utils/routingHelper';
  import { minLength, validateEmail } from '@/utils/formValidators';

  import { Product, ProductIcon } from '@/models/product/product';
  import { IProductIdAreaMapPayload, CartState } from '@/models/store/cart';
  import { ISizes } from '@/models/product/sizes';
  import { contactFormPayload } from '@/models/product/contactFormHiddenFields';
  import { ProductIsFavoritePayload, ProductPayload } from '@/models/productList/productListPayloadModels';
  import { ProductDetailsParagraph } from '@/models/item-types/paragraph';

  import accordionMixin from '@/mixins/accordion';

  import ProductInfoTabs from '@/components/products/ProductInfoTabs.vue';
  import ProductCertificationIcons from '@/components/products/ProductCertificationIcons.vue';
  import IconTitleAndText from '@/components/itemTypes/base/boxes/iconTitleAndText.vue';
  import Loader from '@/components/shared/Loader.vue';
  import ImagePopup from '@/components/shared/ImagePopup.vue';
  import Breadcrumb from '@/global_components/Breadcrumb.vue';
  import TextInput from '@/components/shared/inputs/TextInput.vue';
  import Modal from '@/components/shared/Modal.vue';
  import SizeGuide from '@/components/products/ProductSizeGuide/ProductSizeGuide.vue';
  import ProductFlags from '@/components/products/ProductFlags.vue';
  import sizeTableConfig from '@/components/products/ProductSizeGuide/config';
  import CustomButton from '@/components/shared/Button.vue';
  import useManageUserWishlist from '@/composables/useManageUserWishlist';
  import useMetaTags from '@/composables/useMetaTags';
  import conf from '@/config';
  import { GA4E } from '@/ga4ecommerce/GA4E';
  import RelatedProductsCarousel from '@/components/carousel/RelatedProductCarousel/RelatedProductsCarousel.vue';
  import { useRouter, useRoute } from 'vue-router';
  SwiperCore.use([Navigation, Pagination, Thumbs, Zoom]);
  const router = useRouter();
  const route = useRoute();
  export default defineComponent({
    components: {
      Breadcrumb,
      Swiper,
      SwiperSlide,
      SizeGuide,
      ProductFlags,
      ProductInfoTabs,
      Loader,
      TextInput,
      ProductCertificationIcons,
      ImagePopup,
      IconTitleAndText,
      Modal,
      CustomButton,
      RelatedProductsCarousel,
    },
    mixins: [accordionMixin],
    props: {
      maxStockWarning: {
        required: false,
        type: Number,
        default: 3,
      },
      componentProps: {
        type: Object as PropType<ProductDetailsParagraph>,
        required: true,
      },
    },
    setup() {
      const store = useStore();
      const { hasAuthenticatedUser } = useAuthenticateUser();

      const { setMetaTags, setOpenGraphMetaTags, setTwitterMetaTags, setCanonicalUrl } = useMetaTags();
      const { toggleProductInWishlist } = useManageUserWishlist();

      const product = computed(() => store.getters['product/getProduct']);

      const sortBySizes = computed(() => {
        // Sort sizes by size from low to high
        const sorted = product?.value?.VariantInfo?.VariantInfo?.toSorted((a, b) => {
          if (a.OptionName > b.OptionName) {
            return 1;
          }
          if (a.OptionName < b.OptionName) {
            return -1;
          }

          // names must be equal
          return 0;
        });

        return sorted || [];
      });

      GA4E.viewItem(product.value);

      return {
        product,
        sortBySizes,
        hasAuthenticatedUser,
        toggleProductInWishlist,
        setMetaTags,
        setOpenGraphMetaTags,
        setTwitterMetaTags,
        setCanonicalUrl,
      };
    },
    data() {
      return {
        // s4s uses related products as variants due to messy backend.
        certifications: [] as ProductIcon[],
        productID: '',
        productLoaded: false,
        liveDataLoaded: false,
        quantity: 1,
        sizeSelected: {} as ISizes,
        sizeOpen: false,
        sizes: [] as ISizes[],
        locations: [] as { LocationName: string }[],
        OnlyInWebstore: false,
        showSizeGuideModal: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        swiperOption: {
          breakpoints: {
            320: {
              slidesPerView: 1,
              spaceBetween: 0,
              pagination: {
                el: '.swiper-pagination',
              },
            },
            780: {
              slidesPerView: 2,
              spaceBetween: 0,
            },
            998: {
              slidesPerView: 3,
              spaceBetween: 0,
              pagination: false,
            },
          },
        },
        thumbsOption: {
          breakpoints: {
            320: {
              direction: 'horizontal',
            },
            780: {
              direction: 'vertical',
            },
          },
        },
        thumbsSwiper: null,
        imagePrefix: conf.dwUrl,
        cancelToken: undefined as any,
        gettingVariants: false,
        showContactForm: false,
        contactFormPosted: false,
        contactFormPosted_Message: 'Takk fyrir að hafa samband.',
        contactFormLoader: false,
        contactEmail: '',
        contactEmail_Validator: [minLength(1, 'Netfang má ekki vera tómt'), validateEmail('Netfang ekki löglegt')],
        contactKennitala: '',
        contactMessage: '',
        showContactMissingEmailMessage: false,
        contactMissingEmailMessage: 'Fylla þarf út "Netfang" reit',
        showKennitalaInvalidMessage: false,
        kennitalaInvalidMessagea: 'Kennitala þarf að vera 10 stafir',
        hiddenFormFields: [] as any,
        imageToZoom: '',
        showImagePopup: false,
        documentScrollPos: 0,
      };
    },
    computed: {
      getFirstProductImage() {
        if ((this.product as any).ImagePatternImages?.length > 0) {
          return (this.product as any).ImagePatternImages[0]?.Value;
        }
        return (this.product as any).DefaultImage?.Value;
      },
      ...mapState('cart', {
        addedToCart: (state) => (state as CartState).cartOverviewPageOptions.addedToCart,
      }),
      defaultSizeSelected(): any {
        if (Object.keys(this.sizeSelected).length > 0) {
          return this.sizeSelected;
        }

        const sizes = cloneDeep(this.product?.VariantInfo?.VariantInfo || []);

        if (sizes.length === 1) {
          return {
            OptionName: sizes[0].OptionName,
            OptionID: sizes[0].OptionID,
            Stock: sizes[0].Stock,
          };
        }

        if (sizes.length > 1) {
          return { OptionName: 'VELDU STÆRÐ', OptionID: '', Stock: 0 };
        }
        return null;
      },
      relatedProducts(): any[] {
        return this.$store.getters['product/getRelatedProducts'] as any[];
      },
      isPurchaseButtonDisabled(): boolean {
        const outOfStock = this.product?.StockLevel <= 0; // Total inventory for the product
        const variantOutOfStock = this.defaultSizeSelected?.Stock <= 0; // Inventory of selected variant
        const isSerpontun = this.isSpecialOrder;
        const isNeverOutOfStock = this.product?.NeverOutOfstock;
        // const isProductLoading = this.product?.StockLevel === undefined;
        switch (true) {
          case isSerpontun: // Allways allow purchase if sérpöntun
            return false;
          case isNeverOutOfStock: // Always allow purchase if never out of stock
            return false;
          case outOfStock: // Dissable button if total inverntory is 0
            return true;
          case variantOutOfStock: // Dissable buttin if chosen variant is out of stock
            return true;
          // case isProductLoading: // Disable while loading product info
          //   return true;
          default:
            return false;
        }
      },
      showSizeVariants(): boolean {
        return this.product?.VariantInfo?.VariantInfo.length > 1 && !this.isSpecialOrder;
      },
      pdfPrefix(): string {
        return `${conf.dwUrl}/`;
      },
      pdfFile(): string | null {
        return this.product?.ProductFields?.PdfFileLink?.Value;
      },
      pdfFile2(): string | null {
        return this.product?.ProductFields?.PdfFileLink2?.Value;
      },
      pdfUserManual(): string | null {
        return this.product?.ProductFields?.PdfUserManual?.Value;
      },
      youtubeLink(): any {
        return this.product?.ProductFields?.YoutubeLink?.Value;
      },
      youtubeImage(): string {
        const youtubeLink = this.product?.ProductFields?.YoutubeLink?.Value;
        if (youtubeLink) {
          const id = extractYoutubeId(youtubeLink);
          const image = `https://img.youtube.com/vi/${id}/hqdefault.jpg`;
          return image;
        }
        return '';
      },
      webShop(): string {
        return this.product?.ProductFields?.WebShops.Value;
      },
      hasSizeTableData(): boolean {
        return sizeTableConfig.settings.productHasValidConfig(sizeTableConfig, this.product);
      },
      isTablet() {
        return window.innerWidth < 781;
      },
      isSpecialOrder() {
        return this.product?.ProductFields?.IsSerpontun?.Value;
      },
      isGetOffer() {
        return this.product?.ProductFields?.Fatilbod?.Value;
      },
      isFlagPole() {
        return this.product?.ProductFields?.ProductFamily?.Value.some((family) => family.Value === 'fanar');
      },
      linkFor360View() {
        return this.product?.ProductFields?.Link360View?.Value;
      },
      webEmailTarget() {
        if ((this.webShop || getAreaForPage(this.$router)?.path || '').toLowerCase().includes('brp')) {
          return 'brp@ellingsen.is';
        }
        return 'lager@ellingsen.is';
      },
      getStock() {
        if (this.sizeSelected?.Stock !== undefined) {
          return this.sizeSelected.Stock;
        }
        if (this.product?.VariantInfo?.VariantInfo?.length === 1) {
          return this.product.VariantInfo.VariantInfo[0].Stock;
        }
        return 0;
      },
      productIdQuery() {
        return this.$route.query.ProductId;
      },
    },
    created() {
      this.contactFormHiddenFields();
    },
    mounted() {
      document.addEventListener('scroll', this.findScrollPos);
    },
    beforeUnmount() {
      document.removeEventListener('scroll', this.findScrollPos);
    },
    methods: {
      validateQuantity() {
        if (this.quantity > this.getStock) {
          this.quantity = this.getStock;
        }
      },
      findScrollPos(e) {
        const currentScrollPosition = window.scrollY;
        this.documentScrollPos = currentScrollPosition;
      },
      initializePage() {
        this.productID = this.$route.query.ProductId as string;

        if (!this.productID && this.componentProps?.defaultProduct?.length > 0) {
          const defaultProduct = first(this.componentProps.defaultProduct);
          this.productID = get(defaultProduct || {}, 'Id', '');
        }

        const cacheProduct = this.$store.getters['product/getProduct'];
        if (cacheProduct.Id !== undefined && cacheProduct.Id === this.productID) {
          this.productLoaded = true;
          const payload = this.getPayload();
          this.setProductMetaTags();

          if (cacheProduct.Price === undefined || cacheProduct.VariantInfo === undefined) {
            this.getLiveData(payload);
          }
          const cacheRelatedProducts = this.$store.getters['product/getRelatedProducts'];
          if (cacheRelatedProducts.length === 0) {
            this.getRelatedProducts(payload);
          }
        }
        this.getProduct();
      },
      getProduct() {
        const payload = this.getPayload();
        this.$store
          .dispatch('product/setProduct', payload)
          .then((success) => {
            const hasId = this.product?.Id !== undefined;
            const isActive = this.product.Active;
            const hasWebShops = this.product.ProductFields?.WebShops?.Value.length > 0;
            const isNeverOutOfStock = this.product.NeverOutOfstock;

            if (success && hasId && ((isActive && hasWebShops) || isNeverOutOfStock)) {
              this.setProductMetaTags();
              this.getLiveData(payload);
              this.getRelatedProducts(payload);
              this.loadVariantAndGetColor(this.product);
            } else if (!isActive || this.product.ProductFields.WebShops.Value.length === 0) {
              this.$router.push({ name: '404' });
            }
          })
          .catch((error) => {
            console.log(`error: ${error}`);
          })
          .finally(() => {
            this.productLoaded = true;
          });
      },
      getLiveData(payload: any = undefined) {
        this.$store.dispatch('product/setProductLiveData', payload);
      },
      getRelatedProducts(payload: any = undefined) {
        this.gettingVariants = true;
        this.$store
          .dispatch('product/setRelatedProducts', payload)
          .then((success) => {
            if (success) {
              this.$store.dispatch('product/setRelatedProductsLiveData', payload);
              this.gettingVariants = false;
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.gettingVariants = false;
          });
      },
      async addProductToCart() {
        const allowPurchase = !this.isPurchaseButtonDisabled;
        if (allowPurchase) {
          const productObject = {
            ProductID: this.product.Id,
            VariantID: this.defaultSizeSelected.OptionID,
            Manufacturer: this.product.Manufacturer?.Name,
            ProductName: this.product.Name,
            Price: this.product.Price?.PriceWithVat,
            OriginalPrice: this.product.PriceBeforeDiscount?.PriceWithVat,
            CurrencySymbol: 'Kr',
            ImagePath: this.product.ImagePatternImages[0]?.Value,
            Quantity: this.quantity,
          } as Product;

          await this.$store.dispatch('cart/addToCart', productObject);
          this.$store.commit('cart/setProductIdArea', {
            productId: productObject.ProductID,
            area: getAreaForPage(this.$router)?.path,
          } as IProductIdAreaMapPayload);
        }
      },
      selectSize(size: any) {
        this.OnlyInWebstore = size.OnlyInWebstore;

        //= Refactor = Backend: Location er núna tengt varianti, ef vara er ekki með variant kemur location ekki út.
        // Það þarf að finna leið til að fá localtion(lagerstöðu) á vöru sem er án variants (getur verið "ONE SIZE" eða bara tómt)
        this.locations = size.VariantLocations;

        this.sizeSelected = size;
        this.sizeOpen = false;
      },
      async contactForProduct() {
        const validEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.contactEmail);
        const validKennitala = this.contactKennitala.length === 0 || this.contactKennitala.length === 10;
        if (validEmail && validKennitala) {
          this.showContactMissingEmailMessage = false;
          this.showKennitalaInvalidMessage = false;

          // Create payload
          const payload: contactFormPayload = {
            productID: this.productID || this.product?.Id,
            name: this.contactEmail,
            email: this.contactEmail,
            message: this.contactMessage,
            hiddenFields: this.hiddenFormFields,
            kennitala: this.contactKennitala,
            webEmail: this.webEmailTarget,
          };
          // Start button loader
          this.contactFormLoader = true;
          // Make API call
          await ProductAPI.postContactForm(payload);
          this.contactFormPosted = true;
          this.contactFormLoader = false;
          // Return success/error message
        } else {
          // Display Error Message
          this.showContactMissingEmailMessage = !validEmail;
          this.showKennitalaInvalidMessage = !validKennitala;
        }
      },
      async contactFormHiddenFields() {
        // fetch hidden form fields
        const hiddenFormFields = await ProductAPI.getContactFormHiddenFields();
        this.hiddenFormFields = hiddenFormFields;
        return hiddenFormFields;
      },
      openSizeGuide() {
        this.showSizeGuideModal = true;
      },
      closeSizeGuide() {
        this.showSizeGuideModal = false;
      },
      setThumbsSwiper(swiper) {
        this.thumbsSwiper = swiper;
      },
      getColor() {
        return this.product.ProductFields?.BaseColor?.Value[0]?.Name.split('#')[0].toUpperCase().trim();
      },
      getColorValue(product: any) {
        return product.ProductFields?.BaseColor?.Value[0]?.Value.trim();
      },
      loadVariantAndGetColor(product) {
        this.$store.commit('product/setProduct', product);
        this.sizeSelected = {} as ISizes;
        const path = `${this.$route.path}?ProductId=${product.Id}`;
        const { href } = this.$router.resolve(path);
        window.history.replaceState({}, '', href);
      },
      primaryImage(product: any) {
        if (product.ImagePatternImages?.length > 0) {
          return this.imagePath(product.ImagePatternImages[0]?.Value, 90, 90);
        }
        return this.imagePath(product.DefaultImage?.Value, 90, 90);
      },
      imagePath(image: string, width: number, height: number) {
        return this.imagePrefix + ImageHandler(image, width, height);
      },
      hasDiscount() {
        return this.product?.Price?.PriceWithVat < this.product?.PriceBeforeDiscount?.PriceWithVat;
      },
      isNew() {
        if (this.product?.Created !== undefined) {
          const createdDate = new Date(this.product.Created);
          const tenDaysAgo = new Date();
          tenDaysAgo.setDate(new Date().getDate() - 10);
          return tenDaysAgo < createdDate;
        }
        return false;
      },
      scrollToElement(elementID: string) {
        const element = document.getElementById(elementID) as HTMLElement;
        if (element) {
          const headerOffset = 180;
          const elementPosition = element.getBoundingClientRect().top;
          const offsetPosition = elementPosition - headerOffset;

          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
          });
        }
      },
      getPayload() {
        if (typeof this.cancelToken !== typeof undefined) {
          this.cancelToken.cancel('Operation canceled due to new request.');
        }
        this.cancelToken = axios.CancelToken.source();

        return {
          productId: this.productID,
          cancelToken: this.cancelToken.token,
        } as ProductPayload;
      },
      quantityIncrement() {
        if (this.quantity < this.getStock || this.product?.NeverOutOfstock) {
          this.quantity += 1;
        }
      },
      showImgPopup(imgSrc) {
        this.imageToZoom = imgSrc;
        this.showImagePopup = true;
      },
      closeImgPopup() {
        this.showImagePopup = false;
      },
      toggleFavorite() {
        const payload = {
          productId: this.product.Id,
          isFavorite: this.product.IsFavorite,
        } as ProductIsFavoritePayload;
        this.toggleProductInWishlist(payload);
      },
      beforeEnterFly(el) {
        if (!this.isTablet) {
          const copyImage = document.querySelectorAll('.productImage')[0] as HTMLElement;
          const startingPoint = document.querySelectorAll('.productImage_MainContainer')[0] as HTMLElement;
          gsap.set(el, {
            width: `${copyImage.offsetWidth}px`,
            height: `${copyImage.offsetHeight}px`,
            left: `${startingPoint.getBoundingClientRect().left + 1}px`,
            top: `${startingPoint.getBoundingClientRect().top + 1 + this.documentScrollPos}px`,
          });
        }
      },
      enterFly(el, done) {
        if (!this.isTablet) {
          // const destination = document.querySelectorAll(".cart-item__image")[0] as HTMLElement;
          const destination = document.querySelectorAll('.cart-item__image')[0];

          gsap.to(el, {
            width: `${destination.getBoundingClientRect().width}px`,
            height: `${destination.getBoundingClientRect().height}px`,
            left: `${destination.getBoundingClientRect().left}px`,
            top: `${destination.getBoundingClientRect().top + this.documentScrollPos}px`,
            delay: 0.5,
            duration: 0.9,
            ease: 'power1.in',
            onComplete: done,
          });

          gsap.to(el, {
            opacity: 0,
            delay: 1.4,
            duration: 0,
            onComplete: done,
          });
        }
      },
      beforeEnterFade(el) {
        gsap.set(el, {
          opacity: 0.3,
        });
      },
      enterFade(el, done) {
        gsap.to(el, {
          opacity: 1,
          delay: 1.5,
          ease: 'linear',
          duration: 1.5,
          onComplete: done,
        });
      },
      setProductMetaTags() {
        let description = this.product?.LongDescription as string;
        const div = document.createElement('div');
        div.innerHTML = description;
        description = (div.textContent || div.innerText || '')?.substring(0, 200).trim();
        const title = this.product?.Name;
        const image = `${conf.dwUrl}${this.product?.DefaultImage?.Value}`;

        this.setCanonicalUrl(document.location.href);

        this.setMetaTags(
          {
            title: { name: 'title', content: title },
            description: { name: 'description', content: description },
            keywords: { name: 'keywords', content: this.product?.MetaKeywords },
          },
          true,
        );

        this.setOpenGraphMetaTags({
          'og:type': 'og:product',
          'og:title': title,
          'og:description': description,
          'og:image': image,
        });

        this.setTwitterMetaTags({
          'twitter:title': title,
          'twitter:description': description,
          'twitter:image': image,
          'twitter:site': document.location.href,
          'twitter:card': 'summary_large_image',
        });

        document.title = title;
      },
    },
    watch: {
      productIdQuery: {
        handler(newValue) {
          if (newValue) {
            this.initializePage();
          }
        },
        immediate: true,
      },
    },
  });
